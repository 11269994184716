import ListView from 'components/ListView/ListView';
import Spacer from 'components/Spacer/Spacer';
import { useEffect, useMemo, useState } from 'react';
import Tile from 'components/Tiles/Tile';
import useOperationReports from 'OperationReports/useOperationReports';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import EmployeeHeaderBar from 'Employees/EmployeeHeaderBar';
import { PersonOutletContextType } from 'Persons/PersonOutlet';
import OperationReportListItem from 'OperationReports/OperationReportListItem';
import ScrollView from 'components/ScrollView';
import { FaPlus } from 'react-icons/fa';
import { usePDF } from '@react-pdf/renderer';
import OperationReportPdf from 'OperationReports/OperationReportPdf';

const EmployeeDocuments = () => {

    const { person } = useOutletContext<PersonOutletContextType>()
    const { search } = useLocation()
    const [operationReportPdf, updateOperationReportPdf] = usePDF()

    const params = useMemo(() => ({
        employee_id: person ? [person?.id] : []
    }), [person])

    const { data:operationReports } = useOperationReports(params)
    const navigate = useNavigate()

    useEffect(() => {
        if(operationReportPdf.blob &&!operationReportPdf.error && !operationReportPdf.loading) {
            var win = window.open(operationReportPdf.url, '_blank')
            win?.focus()
        }
    }, [operationReportPdf])

    return (
        <>
            <EmployeeHeaderBar person={person}/>
            <Spacer medium shrink>
                <Tile
                    title='Stundenbelege'
                    actions={[
                        {
                            Icon: <FaPlus />,
                            execute:() => navigate(`./Stundenbeleg/${search}`)
                        }
                    ]}
                >
                    <ScrollView>
                        <ListView
                            placeholder={"Keine Belege vorhanden"}
                            data={operationReports}
                            listItem={(report) => (
                                <OperationReportListItem 
                                    report={report}
                                    onClick={() => {
                                        updateOperationReportPdf(<OperationReportPdf report={report}/>)
                                    }}
                                />
                            )}
                        />
                    </ScrollView>                    
                </Tile>
            </Spacer>
        </>
    )
}

export default EmployeeDocuments