import { useMemo } from "react"
import { IModel } from "./IModel"
import useModelUpdate from "./Actions/useModelUpdate";
import { Actions } from "../app/Actions/IAction";
import useModelCreate from "./Actions/useModelCreate";
import useModelDelete from "./Actions/useModelDelete";
import { PartialObject } from "lodash";
import useObject, { IObject } from "Objects/useObject";

export type IModelInstance<T> = IObject & {
    model: IModel<T>,
    actions: Actions,
}

export default function useModelInstance<T>(model:IModel<T>, initialData:PartialObject<T>={}) : IModelInstance<T> {

    const object = useObject(model.objectDefinition, initialData)

    const update = useModelUpdate(model, object)
    const create = useModelCreate(model, object)
    const erase = useModelDelete(model, object)

    const actions = useMemo(() => {
        return {
            update,
            create,
            erase
        }
    }, [create, update, erase])

    return {
        ...object,
        model,
        actions
    }
}