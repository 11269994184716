
import ameli from 'Api/ameli';
import { useQuery } from '@tanstack/react-query';
import EmployeeService from './EmployeeService';

export interface EmployeeServiceGetParams {
    employeeId?: number
}

export function useEmployeeServices(params: EmployeeServiceGetParams) {
    return useQuery({
        queryKey: ['employee_services', params],
        queryFn: () => ameli.get<EmployeeService[]>(`employee_services`, { params }).then(res => res.data),
        enabled: params != null
    })
}

export function useEmployeeService(id) {
    return useQuery({
        queryKey: ['employee_services', id],
        queryFn: () => ameli.get<EmployeeService>(`employee_services/${id}`).then(res => res.data),
        enabled: Boolean(id)
    })
}