import Label from "components/labels/Label"
import Spacer from "components/Spacer/Spacer"
import moment from "moment"
import ClickableOpacity from "components/Decorator/ClickableOpacity";

const NoteCard = ({note, showEditTimestamp=true, onClick=(note)=>{}, ...props}) => {

    const renderTimestamp = showEditTimestamp && note.timestamp;
    const dateString = moment(note.timestamp).format('DD.MM.yyyy');
    const timeString = moment(note.timestamp).format('HH:mm');

    return (
        <ClickableOpacity onClick={() => onClick(note)}>
                <Spacer medium>
                    <Label>{note.title}</Label>
                    {
                        renderTimestamp && (
                            <Label small subtle>Geändert am {dateString} um {timeString} Uhr</Label>
                        )
                    }
                    {
                        note.details !== '' &&
                        <>
                            <Spacer vSmall/>
                            <Label small allowWhiteSpace>
                                {note.details}
                            </Label>
                        </>
                    }
                </Spacer>
        </ClickableOpacity>
    )
}

export default NoteCard