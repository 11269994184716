import styled from 'styled-components'
import ClickableOpacity from '../components/Decorator/ClickableOpacity'
import Theme from 'Theming/Theme'

const Container = styled.span`
    border-radius: 4px;
    background-color: ${ props =>
    props.backgroundColor ? props.backgroundColor : 
        props.primary ? Theme.primaryColor :
        props.secondary ? Theme.secondaryColor :
        props.danger ? Theme.dangerColor :
        props.warning ? Theme.warningColor :
        props.success ? Theme.successColor :
        '#ccc'
    };
    color: ${props =>
        props.color ? props.color : 
        props.primary ? Theme.whiteColor :
        props.secondary ? Theme.whiteColor :
        props.danger ? Theme.whiteColor : 
        props.warning ? Theme.whiteColor :
        props.success ? Theme.whiteColor : 
        '#555'
    };
    padding: 0.1em 0.5em;
    font-size: 0.75em;
    height: 1.5em;
    margin-right: 0.2em;
    margin-bottom: 0.2em;
    display: inline-flex;
    align-items: center;
    justify-content: center;
`

const Tag = ({...props}) => {

    return (
        <ClickableOpacity onClick={props.onClick}>
            <Container {...props}/>
        </ClickableOpacity>
    )
}

const SecondaryTag = (props) => {
    return (
        <Tag 
            color={Theme.whiteColor} 
            backgroundColor={Theme.secondaryColor} 
            {...props}
        />
    )
}


export {
    Tag,
    SecondaryTag
}