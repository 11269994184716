import moment from "moment"

export const localizeDateString = (string, fallback='') => {
    return string ? moment(string).format('DD.MM.YY') : fallback;
}

export const formatTimeString = (string, fallback="") => {
    return string ? moment(string, "HH:mm").format("HH:mm") : fallback
}

export const getMonthName = (monthNumber) => {
    return moment().set('month', monthNumber-1).format('MMMM');
}

export const formatDateString = (string, fallback='') => {
    return string ? moment(string).format("YYYY-MM-DD") : fallback
}

export const formatTimePeriod = (from, to, fallback = "") => {
    const fromString = formatTimeString(from, null);
    const toString = formatTimeString(to, null);

    if (fromString != null && toString != null) {
        return `${fromString}-${toString}`
    }
    if (fromString != null) {
        return `Ab ${fromString}`
    }
    if (toString != null) {
        return `Bis ${toString}`
    }
    return fallback
}

export const formatPeriodString = (begin, end, fallback='', startPrefix='Ab', endPrefix='Bis') => {
    const beginString = localizeDateString(begin, null);
    const endString = localizeDateString(end, null);
    if(beginString && endString) {
        return beginString === endString ? beginString : `${beginString} - ${endString}`
    }
    if(beginString) {
        return `${startPrefix} ${beginString}`
    }
    if(endString) {
        return `${endPrefix} ${endString}`
    }
    return fallback;
}