
import IconWrapper from 'components/Icons/IconWrapper';
import styled from 'styled-components';
import Theme from 'Theming/Theme';
import IAction from './IAction';

const Container = styled.div`
    display: flex;
    gap: 0.1em;
`


interface ActionIconsProps {
    actions?: IAction[]
}

export default function ActionIcons({ actions }: ActionIconsProps) {
    return (
        <Container>
            {actions?.map(({ Icon, execute }, index) => (
                <IconWrapper
                    size='16px'
                    color={Theme.primaryColor}
                    key={index}
                    onClick={() => execute()}
                >
                    {Icon}
                </IconWrapper>
            ))}
        </Container>
    )
}