import { useForm } from "components/form/form";
import Modal, { ModalFooter } from "components/modal/Modal";
import OperationTaskForm from "./OperationTaskForm";
import Spacer from "components/Spacer/Spacer";
import SubmitButton from "Forms/SubmitButton";

export default function OperationTaskModal({
    selected:data, 
    visible, 
    setVisible,
    dispatchAction
}) {

    const isUpdate = data && 'id' in data
    const title = isUpdate ? "Aufgabe bearbeiten" : "Aufgabe erfassen";

    const {
        formData,
        handleFormChange,
        formDataChanged,
        formDataValid,
        setFormData
    } = useForm(
        data,
        {
            'validate': (data) => data && (Number.isInteger(data.type_id) && data.type_id >= 0)
        }
    );

    const submitCallback = () => {
        setVisible(false)
    }

    const createCallback = () => {
        setFormData({...data, type_id: formData.type_id})
    }

    return (
        <Modal
            minWidth='37em'
            visible={visible}
            setVisible={setVisible}
            title={title}>
            { formData &&
                <Spacer medium>
                    <OperationTaskForm 
                        formData={formData}
                        handleFormChange={handleFormChange}
                    />
                </Spacer>
            }
            <ModalFooter>
                <SubmitButton
                    onClick={() => dispatchAction('update', formData).then(submitCallback)}
                    visible={isUpdate}
                    disabled={!formDataChanged || !formDataValid}>
                    Übernehmen
                </SubmitButton>
                <SubmitButton
                    onClick={() => dispatchAction('delete', formData.id).then(submitCallback)}
                    visible={isUpdate}>
                    Löschen
                </SubmitButton>
                <SubmitButton
                    onClick={() => dispatchAction('create', formData).then(createCallback)}
                    visible={!isUpdate}
                    disabled={!formDataValid}>
                    Erfassen
                </SubmitButton>
            </ModalFooter>
        </Modal>
    )
}