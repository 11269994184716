import Label from "components/labels/Label"
import { useMemo } from "react"


const AssignmentHoursLabel = ({ assignment, ...props }) => {

    var label = useMemo(() => {
        if (!assignment || !assignment.monthly_hours) {
            return "? Stunden"
        }
        return `${ assignment.monthly_hours } Stunden`
    }, [assignment])

    return (
        <Label {...props}>{label}</Label>
    )
}

export default AssignmentHoursLabel