import IAction, { ActionOptions } from "../../app/Actions/IAction";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { IModel } from "Models/IModel";
import ameli from "Api/ameli";
import Theme from './../../Theming/Theme';
import { PartialObject } from "lodash";
import { IObject } from "Objects/useObject";
import { useCallback, useMemo } from "react";

export default function useModelUpdate<T>(model:IModel<T>, object:IObject) : IAction {
    const queryClient = useQueryClient()

    const mutation = useMutation({
        mutationKey: [model.apiPath, 'patch'],
        mutationFn: (data:PartialObject<T>) => ameli.patch(model.apiPath, data),
    })

    const execute = useCallback((options?:ActionOptions) => {
        return mutation.mutate(object.value, {
            onSuccess: () => {
                queryClient.invalidateQueries({
                    queryKey: [model.apiPath]
                })
                model.queryKeys?.forEach(key => {
                    queryClient.invalidateQueries({
                        queryKey: [key]
                    })
                })
                options?.onSuccess()
            },
            onError: () => {
                options?.onError()
            },
            onSettled: () => {
                options?.onSettled()
            }
        })
    }, [mutation, queryClient, object, model])

    const enabled = useMemo(() => {
        return object.valid && object.isUpdate
    }, [object])

    const visible = useMemo(() => {
        return object.isUpdate
    }, [object])

    return {
        label: 'Speichern',
        error: mutation.error,
        color:Theme.whiteColor,
        backgroundColor: Theme.primaryColor,
        isPending: mutation.isPending,
        execute,
        enabled,
        visible
    }
}