import TagContainer, { TagProps } from "Tags/TagContainer"
import Theme from "Theming/Theme"
import ActionIcons from "app/Actions/ActionIcons"
import IAction from "app/Actions/IAction"
import ClickableOpacity from "components/Decorator/ClickableOpacity"
import ConditionalView from "components/Decorator/ConditionalView"
import styled from 'styled-components'


const Container = styled.div`
    background-color: #fefefe;
    border: 1px solid #ddd;
    border-radius: 6px; 
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
    padding: ${Theme.vMedium} ${Theme.hMedium};
    display: flex;
    flex-direction: column;
    gap: 8px;
    position: relative;
`

const Banner = styled.div`
    position: absolute;
    left: 0px;
    top: 0;
    bottom: 0;
    width: 0.35em;
    background-color: ${props => props.backgroundColor};
    border-radius: 6px 0px 0px 6px;
`

const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
`

const Title = styled.span`
    display: block;
    font-weight: bold;
    font-size: ${props => {
        return (
            props.size === 'small' ? '0.9rem' : 
            props.size === 'large' ? '1.1rem' : 
            '1rem'
        )
    }};
    line-height: ${props => {
        return (
            props.size === 'small' ? '1.4rem' :
            props.size === 'large' ? '1.6rem' :
            '1.4rem'
        )
    }};
    color: ${Theme.textColor};
    flex: 1;
`

const TitleRow = styled.div`
    display: flex;
    flex-direction: row;
    gap: 0.5em;
`

const Subtitle = styled.span`
   display: block;
   font-size: ${props => {
        return (
            props.size === 'small' ? '0.8rem' :
                props.size === 'large' ? '1.0rem' :
                    '0.9rem'
        )
    }};;
   font-weight: 400;
   line-height: ${props => {
        return (
            props.size === 'small' ? '1.0rem' :
            props.size === 'large' ? '1.2rem' :
            '1.1rem'
        )
    }};;
   color: ${Theme.subtleTextColor};
`

type Size = 'small' | 'medium' | 'large'

export interface IListItemOptions {
    onClick?: () => any,
}

export interface IListItemProps extends IListItemOptions{
    title: string,
    subtitle?: string,
    tags?: TagProps[],
    children? : React.ReactNode,
    actions?: IAction[],
    bannerColor?,
    size?: Size
}

export default function ListItem({
    title,
    subtitle,
    onClick,
    tags,
    children,
    actions,
    bannerColor,
    size='small'
}: IListItemProps) {
    return (
        <ClickableOpacity onClick={onClick}>
            <Container>
                { bannerColor && <Banner backgroundColor={bannerColor}/> }
                <TitleContainer>
                    <TitleRow>
                        <Title size={size}>{title}</Title>
                        <ActionIcons actions={actions}/>
                    </TitleRow>
                    <ConditionalView condition={subtitle}>
                        <Subtitle size={size}>{subtitle}</Subtitle>
                    </ConditionalView>
                </TitleContainer>
                <TagContainer tags={tags}/>
                { children }
            </Container>
        </ClickableOpacity>
    )
}