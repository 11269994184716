import { useState } from "react";
import IInputProps from "./IInputProps";
import Input from "./Input";
import InputWrapper from "./InputWrapper";

interface ITextInputProps extends IInputProps {
    length?: number
}

export default function TextInput({
    name,
    value,
    setValue,
    allowNull,
    length,
    ...props
}: ITextInputProps) {

    const [focused, setFocused] = useState(false)

    const handleChange = (e) => {
        let value = e.target.value
        if(allowNull && value === '') {
            value = null
        }
        setValue(value)
    }

    return (
        <InputWrapper focused={focused} setFocused={setFocused} {...props}>
            <Input 
                tabIndex={0}
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
                name={name}
                type='text' 
                onChange={handleChange} 
                value={value || ''}
                {...props}
            />
        </InputWrapper>
    )
}