import Label from 'components/labels/Label';
import Spacer from 'components/Spacer/Spacer';
import ListItem from '../components/ListView/ListItem';

export default function OperationTaskListItem({task, ...props}) {
    
    return (
        <ListItem {...props}>
            <Spacer medium>
                <Label>{task.title ?? ""}</Label>
                <Label subtle small>{task.subtitle ?? ""}</Label>
            </Spacer>            
        </ListItem>
    );
}