import { generateBooleanField, generateDateField, generatePositiveFloatField, generatePositiveIntegerField, generateTextField, generateTimeField } from "Forms/formFieldFactory"
import useForm from "Forms/useForm"
import { useMemo } from "react"

const useEventForm = (event) => {
    const fields = useMemo(() => {
        return {
            id: generatePositiveIntegerField(event?.id, false),
            group_id: generatePositiveIntegerField(event?.group_id, false),
            creator_id: generatePositiveIntegerField(event?.creator_id, true),
            employee_id: generatePositiveIntegerField(event?.employee_id, false),
            customer_id: generatePositiveIntegerField(event?.customer_id, false),
            supplement_id: generatePositiveIntegerField(event?.supplement_id, false),
            date: generateDateField(event?.date, true),
            time: generateTimeField(event?.time, false),
            duration: generatePositiveIntegerField(event?.duration, false),
            kilometer: generatePositiveFloatField(event?.kilometer, false),
            kilometer_cost: generatePositiveFloatField('kilometer_cost' in event ? event.kilometer_cost : 0.5, false),
            hourly_wage: generatePositiveFloatField('hourly_wage' in event ? event.hourly_wage : 35.0, false),
            title: generateTextField(event?.title, true),
            details: generateTextField(event?.details, false),
            confirmed: generateBooleanField('confirmed' in event ? event.confirmed : null, false),
            absent: generateBooleanField(event?.absent, false),
            demand: generatePositiveIntegerField(event?.demand, false),
            alternatives: {
                initialValue: event.alternatives || [],
                validate: (value) => Array.isArray(value)
            }
        }
    }, [event])
    
    return useForm(fields)
}

export default useEventForm