import OperationNoteList from "OperationNotes/OperationNoteList"
import TextButton from "components/TextButton"
import { FaBook, FaPlus } from "react-icons/fa"
import useOperationNotes, { useOperationNoteActions } from '../OperationNotes/useOperationNotes';
import { useMemo } from "react"
import useModal from "components/modal/useModal"
import OperationNoteModal from "OperationNotes/OperationNoteModal"
import Tile, {  TileHeader, TileIcon, TileTitle } from "components/Tiles/Tile";

const CustomerOperationNoteTile = ({customerId}) => {

    const params = useMemo(() => {
        return { customer_id: customerId }
    }, [customerId])

    const { data } = useOperationNotes(params)
    const operationNoteActions = useOperationNoteActions()
    const modal = useModal()

    return (
        <Tile
            title='Anmerkungen'
            Icon={FaBook}
            actions={[
                {
                    Icon: <FaPlus />,
                    onClick:() => modal.select({ customer_id: customerId })
                }
            ]}
        >
            <OperationNoteList data={data} onClick={modal.select} placeholder="Keine Anmerkungen"/>
            <OperationNoteModal {...modal} dispatchAction={operationNoteActions.dispatch}/>
        </Tile>
    )
}

export default CustomerOperationNoteTile