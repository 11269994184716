import Label from "components/labels/Label"
import { useMemo } from "react"


const AssignmentCustomerLabel = ({ assignment, ...props }) => {

    var label = useMemo(() => {
        if(!assignment?.customer_id) {
            return "Unbekannt"
        }
        return `${assignment?.customer_first_name} ${assignment?.customer_last_name}`
    }, [assignment, assignment])

    return (
        <Label {...props}>{label}</Label>
    )
}

export default AssignmentCustomerLabel