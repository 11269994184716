import Label from "components/labels/Label"
import { useMemo } from "react"
import { formatPeriodString } from "utils/timeStringUtility"


const AssignmentPeriodLabel = ({ assignment, ...props }) => {

    var label = useMemo(() => {
        if (!assignment) {
            return null
        }
        return formatPeriodString(assignment.start_date, assignment.end_date, null)
    }, [assignment])

    if(!label) {
        return null
    }

    return (
        <Label {...props}>{label}</Label>
    )
}

export default AssignmentPeriodLabel