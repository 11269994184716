import ListView from "components/ListView/ListView"
import Tile from "components/Tiles/Tile"
import { ModalContext } from "components/modal/ModalContext"
import { useContext, useMemo } from "react"
import { useAssignments } from "Assignments/AssignmentApi"
import moment from 'moment';
import AssignmentListItem from "./AssignmentListItem"
import { FaPlus } from "react-icons/fa"


interface Props {
    customerId?:number,
    employeeId?:number,
    title:string,
    modalParams?: any,
    showCustomerName?:boolean,
    showEmployeeName?:boolean
}


const AssignmentTile = ({ 
    customerId,
    employeeId,
    title,
    modalParams={},
    showCustomerName,
    showEmployeeName
 }:Props) => {

    const { assignmentModal } = useContext(ModalContext)

    const params = useMemo(() => ({
        customer_id: customerId,
        employee_id: employeeId,
        start:moment().format('YYYY-MM-DD'),
        end: moment().format('YYYY-MM-DD')
    }), [customerId, employeeId])

    const { data:assignments } = useAssignments(params)

    return (
        <Tile
            title={`${title} (${assignments?.length})`}
            actions={[
                {
                    Icon: <FaPlus />,
                    execute:() => assignmentModal.select(modalParams)
                }
            ]}
        >
            <ListView
                data={assignments}  
                listItem={(assignment) => (
                    <AssignmentListItem
                        assignment={assignment} 
                        onClick={assignmentModal.select}
                        showCustomerName={showCustomerName}
                        showEmployeeName={showEmployeeName}
                    />
                )}  
            />
        </Tile>
    )
}

export default AssignmentTile