import Label from "components/labels/Label"
import ListItem from "components/ListView/ListItem"
import Row from "components/Row"
import Spacer from "components/Spacer/Spacer"
import { FaFile, FaTrash } from "react-icons/fa"
import OperationReport from "./OperationReport"
import IconWrapper from "components/Icons/IconWrapper"
import useOperationReportActions from "./useOperationReportActions"
import styled from 'styled-components';
import ClickableOpacity from "components/Decorator/ClickableOpacity"
import HourLabel from "components/labels/hour-label"
import KilometerLabel from "components/labels/kilometer-label"
import Column from "components/Column"

const ClickableContent = styled(ClickableOpacity)`
    flex: 1
`

interface Props {
    report:OperationReport,
    onClick: () => any
}


const OperationReportListItem = ({ report, onClick }:Props) => {

    const operationReportActions = useOperationReportActions()

    const deleteReport = () => {
        operationReportActions.dispatch(
            'delete',
            report.id
        )
    }

    return (
        <ListItem >
            <Spacer medium>
                <Row vCentered gap='0.5em'>
                    <FaFile size={20} color='#333' />
                    <ClickableContent onClick={onClick}>
                        <Row gap='0.5em'>
                            <Column>
                                <Label medium>{report.yearMonth.format('MMM YYYY')}</Label>
                            </Column>
                            <Column>
                                <HourLabel small subtle value={report.hours}/>
                                <KilometerLabel small subtle value={report.kilometer}/>
                            </Column>
                        </Row>
                    </ClickableContent>
                    <IconWrapper onClick={deleteReport}>
                        <FaTrash/>
                    </IconWrapper>
                </Row>
            </Spacer>
        </ListItem>
    )
}

export default OperationReportListItem