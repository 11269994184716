import OperationTaskList from "OperationTasks/OperationTaskList"
import { FaList, FaPlus } from "react-icons/fa"
import useOperationTasks, { useOperationTaskActions } from "OperationTasks/useOperationTasks";
import useModal from "components/modal/useModal";
import { useMemo } from "react";
import OperationTaskModal from "OperationTasks/OperationTaskModal";
import Tile, {  TileHeader, TileIcon, TileTitle } from "components/Tiles/Tile";
import FlexFill from "components/FlexFill";

const CustomerOperationTaskTile = ({customerId}) => {

    const params = useMemo(() => {
        return { customer_id: customerId }
    }, [customerId])
    const { data } = useOperationTasks(params)
    const operationTaskActions = useOperationTaskActions()
    const modal = useModal()

    return (
        <Tile
            title='Aufgaben'
            Icon={FaList}
            actions={[
                {
                    Icon: <FaPlus />,
                    onClick:() => modal.select({ customer_id: customerId })
                }
            ]}
        >
            <OperationTaskList data={data} onClick={modal.select} placeholder="Keine Aufgaben"/>
            <OperationTaskModal {...modal} dispatchAction={operationTaskActions.dispatch}/>
        </Tile>
    )
}

export default CustomerOperationTaskTile