import Label from "components/labels/Label"
import { useMemo } from "react"


const AssignmentEmployeeLabel = ({ assignment, ...props }) => {

    var label = useMemo(() => {
        if (!assignment?.employee_id) {
            return "Offen"
        }
        return `${assignment?.employee_first_name} ${assignment?.employee_last_name}`
    }, [assignment, assignment])

    return (
        <Label {...props}>{label}</Label>
    )
}

export default AssignmentEmployeeLabel