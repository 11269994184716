import styled from 'styled-components';
import Label, { SectionLabel } from 'components/labels/Label';
import { ReactNode } from 'react';
import Theme from './../../Theming/Theme';
import ConditionalView from 'components/Decorator/ConditionalView';
import TagContainer, { TagProps } from 'Tags/TagContainer';
import IconWrapper from 'components/Icons/IconWrapper';
import IAction from 'app/Actions/IAction';
import ActionIcons from 'app/Actions/ActionIcons';

const Container = styled.div`
    padding: ${Theme.vLarge} 0;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    flex-shrink: 1;
    min-height: 0;
    background-color: white;

    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    flex-grow: ${props => props.grow ? 1 : 0};
    flex-shrink: 0;
`

const Header = styled.div`
    padding: 0 ${Theme.hMedium};
    padding-bottom: ${Theme.vMedium};
    display: flex;
    flex-direction: column;
`

const MainHeader = styled.div`
    display: flex;
`

const IconContainer = styled(IconWrapper)`

`

const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`

const Content = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 0px;
`

interface TileProps {
    title?: string,
    subtitle?: string,
    Icon?: React.ReactNode,
    children?:ReactNode,
    tags?: TagProps[],
    leftActions?:IAction[],
    actions?: IAction[]
}

const Tile = ({
    title,
    subtitle,
    children,
    leftActions,
    actions,
    tags,
    Icon
}: TileProps) => {
    return (
        <Container>
            <ConditionalView condition={ title || subtitle || actions || leftActions || Icon}>
                <Header>
                    <MainHeader>   
                        <ActionIcons actions={leftActions} />          
                        <TitleContainer>
                            <SectionLabel>{title}</SectionLabel>
                            <Label small subtle>{subtitle}</Label>
                        </TitleContainer>
                        <ActionIcons actions={actions}/>
                    </MainHeader>
                    <TagContainer tags={tags}/>
                </Header>
            </ConditionalView>
            <Content>
                { children }
            </Content>
        </Container>
    )
}


const TileHeader = styled.div`
    align-items: center;
    display: flex;
    padding: 0.75em 1em;
`

const TileTitle = styled.span`
    font-size: 1.05em;
    display: block;
    font-weight: 600;
    pointer-events: none;
    user-select: none;
    padding-right: 0.5em;
`


export default Tile 
export type { TileProps }