import EmployeeService from "./EmployeeService";
import DataTypes from "DataTypes/DataTypes";
import { IModel } from "Models/IModel";
import ServiceSelect from "Services/ServiceSelect";
import EmployeeSelect from "Employees/EmployeeSelect";
import { PropertyDefinitions } from "Objects/PropertyDefinition";
import Service from "Services/Service";
import ServiceModel from "Services/ServiceModel";


const properties: PropertyDefinitions = {
    id: {
        type: 'single',
        label: 'Id',
        primaryKey: true,
        valueType: new DataTypes.Integer({
            allowNull: false,
            unsigned: false
        })
    },
    employeeId: {
        type: 'single',
        label: 'Mitarbeiter',
        valueType: new DataTypes.Integer({
            allowNull: false,
            unsigned: false
        }),
        required: true,
        renderControl: (field) => {
            return (
                <EmployeeSelect
                    key={field.path}
                    label={field.label}
                    value={field.value}
                    onChange={({ value }) => field.setValue(value)}
                />
            )
        }
    },
    serviceId: {
        type: 'single',
        label: 'Dienstleistung',
        valueType: new DataTypes.Integer({
            allowNull: false,
            unsigned: false
        }),
        required: true,
    },
    service: {
        ...ServiceModel.objectDefinition,
        renderControl: (field) => {
            return (
                <ServiceSelect
                    key={field.path}
                    label={field.label}
                    value={field.value}
                    onChange={(value) => field.setValue(value)}
                />
            )
        }
    },
    createdAt: {
        type: 'single',
        label: 'Erstellt am',
        valueType: new DataTypes.Date({
            format: 'DD.MM.YY HH:mm',
            allowNull: true
        })
    },
    updatedAt: {
        type: 'single',
        label: 'Bearbeitet am',
        valueType: new DataTypes.Date({
            format: 'DD.MM.YY HH:mm',
            allowNull: true
        })
    },
}

const EmployeeServiceModel: IModel<EmployeeService> = {
    name: 'employeeService',
    labels: ['Gebotene Leistung', 'Gebotene Leistungen'],
    objectDefinition: {
        label: '',
        type: 'object',
        properties,
        adjustData: (data) => {
            const service:Service = data?.values['service']
            return {
                ...data,
                values: {
                    ...data.values,
                    serviceId: service?.id
                },
            }
        }
    },
    apiPath: 'employee_services'
}

export default EmployeeServiceModel