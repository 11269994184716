import FloatInput from "Inputs/FloatInput";
import IntegerInput from "Inputs/IntegerInput";
import TextInput from "Inputs/TextInput";
import styled from 'styled-components';
import Checkbox from "components/checkbox/Checkbox";
import DatePicker from "components/input/DatePicker";
import { ObjectPropertyInstance, PropertyInstance, SinglePropertyInstance } from "./PropertyInstance";
import { excludeProperties } from "./Property";
import { IObject } from "./useObject";
import TextArea from "components/input/TextArea";


const Container = styled.div`
    display: fleX;
    flex-direction: column;
    gap: 0.25em;
`

const generateField = (property: SinglePropertyInstance): React.ReactNode => {

    if(!property) {
        return null
    }

    const getError = () => {
        if (property.valid) {
            return null
        }
        return {
            message: "Invalid"
        }
    }

    if (property.renderControl) {
        return property.renderControl(property)
    }

    switch (property.valueType.label) {
        case 'Integer':
            return (
                <IntegerInput
                    optional={!property.required}
                    key={property.path}
                    name={property.path}
                    value={property.value}
                    setValue={property.setValue}
                    allowNull={property.valueType.allowNull}
                    error={getError()}
                    label={property.label}
                    enabled={property.enabled}
                />
            )
        case 'Float':
            return (
                <FloatInput
                    optional={!property.required}
                    key={property.path}
                    name={property.path}
                    value={property.value}
                    setValue={property.setValue}
                    allowNull={property.valueType.allowNull}
                    error={getError()}
                    label={property.label}
                    enabled={property.enabled}
                />
            )
        case 'Text':
            return (
                <TextInput
                    optional={!property.required}
                    key={property.path}
                    name={property.path}
                    value={property.value}
                    setValue={property.setValue}
                    allowNull={property.valueType.allowNull}
                    error={getError()}
                    label={property.label}
                    enabled={property.enabled}
                />
            )
        case 'Boolean':
            return (
                <Checkbox
                    key={property.path}
                    name={property.path}
                    value={property.value}
                    onChange={({ value }) => property.setValue(value)}
                    allowNull={property.valueType.allowNull}
                    error={getError()}
                    label={property.label}
                    enabled={property.enabled}
                />
            )
        case 'Date': 
            return (
                <DatePicker
                    key={property.path}
                    name={property.path}
                    value={property.value}
                    onChange={(value) => property.setValue(value)}
                    //error={error}
                    label={property.label}
                />

            )
        default:
            return null
    }
}

function generateFields(object: ObjectPropertyInstance) {
    if(!object) {
        return null
    }

    return Object.values<PropertyInstance>(object.properties).map((property) => {  
        if(property?.type === 'object')  {
            if(property.renderControl) {
                return property.renderControl(property)
            }
            return generateFields(property)
        }
        else if(property?.type === 'single') {
            return generateField(property)
        }
        else if (property?.type === 'array') {
            if (property.renderControl) {
                return property.renderControl(property)
            }
        }
    })
}

export interface IModelFormOptions {
    hidden?: string[]
}

export interface IModelFormProps extends IModelFormOptions {
    object: IObject
}

export default function ObjectForm({ object, hidden } : IModelFormProps) {

    if (!object) {
        return null
    }

    return (
        <Container>
            {
                generateFields(excludeProperties(object, hidden))
            }
        </Container>
    )
}