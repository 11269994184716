import IAction, { ActionOptions } from "../../app/Actions/IAction";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { IModel } from "Models/IModel";
import ameli from "Api/ameli";
import Theme from "Theming/Theme";
import { useCallback, useMemo } from "react";
import { IObject } from "Objects/useObject";

export default function useModelDelete<T>(model:IModel<T>, object:IObject) : IAction {
    const queryClient = useQueryClient()

    const mutation = useMutation({
        mutationKey: [model.apiPath, 'delete'],
        mutationFn: (primaryKey: any) => ameli.delete(`${model.apiPath}/${primaryKey}`),
        retry: 0
    })

    const execute = useCallback((options?:ActionOptions) => {
        return mutation.mutate(object.primaryKeyProperty?.value, {
            onSuccess: () => {
                queryClient.invalidateQueries({
                    queryKey: [model.apiPath]
                })
                model.queryKeys?.forEach(key => {
                    queryClient.invalidateQueries({
                        queryKey: [key]
                    })
                })
                options?.onSuccess()
            },
            onError: () => {
                options?.onError()
            },
            onSettled: () => {
                options?.onSettled()
            },
        })
    }, [mutation, model, object, queryClient])

    const enabled = useMemo(() => {
        return object.primaryKeyProperty && object.valid && object.isUpdate
    }, [object])

    const visible = useMemo(() => {
        return object.primaryKeyProperty && object.isUpdate
    }, [object])

    return {
        label: 'Löschen',
        error: mutation.error,
        isPending: mutation.isPending && !mutation.isError,
        color: Theme.whiteColor,
        backgroundColor: Theme.subtleColor,
        confirmMessage: 'Eintrag wirklich löschen?',
        execute,
        enabled,
        visible
    }
}