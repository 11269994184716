import OperationTaskCard from "./OperationTaskListItem";
import ListView from '../components/ListView/ListView';

export default function OperationTaskList({data, onClick, ...props}) {

    return (
        <ListView
            {...props}
            data={data}
            listItem={(task) => (
                <OperationTaskCard
                    key={task.id}
                    task={task}
                    onClick={() => onClick(task)}
                /> 
            )}
        />    
    );
}