import Column from "components/Column"
import ListItem from "components/ListView/ListItem"
import Spacer from "components/Spacer/Spacer"
import AssignmentEmployeeLabel from "./AssignmentEmployeeLabel"
import AssignmentHoursLabel from "./AssignmentHoursLabel"
import AssignmentPeriodLabel from "./AssignmentPeriodLabel"
import Assignment from "./Assignment"
import ConditionalView from "components/Decorator/ConditionalView"
import TagContainer from "Tags/TagContainer"
import { Tag } from "Tags/Tag"
import AssignmentCustomerLabel from "./AssignmentCustomerLabel"

export interface AssignmentListItemProps {
    assignment:Assignment,
    showCustomerName?:boolean,
    showEmployeeName?:boolean,
    onClick?:(assignment:Assignment) => any
}

const AssignmentListItem = ({ 
    assignment, 
    showCustomerName=true, 
    showEmployeeName=true,
    onClick=(assignment) => {}
}: AssignmentListItemProps) => {

    return (
        <ListItem onClick={() => onClick(assignment)}>
            <Spacer medium>
                <Column gap='0.25em'>
                    <div>
                        <ConditionalView condition={Boolean(assignment.advertise)}>
                            <TagContainer>
                                <Tag secondary>Anfrage in Kundenbörse</Tag>
                            </TagContainer>
                        </ConditionalView>
                        <ConditionalView condition={showEmployeeName}>
                            <AssignmentEmployeeLabel assignment={assignment} />
                        </ConditionalView>
                        <ConditionalView condition={showCustomerName}>
                            <AssignmentCustomerLabel assignment={assignment} />
                        </ConditionalView>
                        <AssignmentPeriodLabel small subtle assignment={assignment} />
                        <AssignmentHoursLabel small subtle assignment={assignment} />
                    </div>
                </Column>
            </Spacer>
        </ListItem>
    )
}

export default AssignmentListItem